.Resources {
}

.Resources a {
  text-decoration: none;
}

.Resources-tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.Resources-tile {
  max-width: 400px;
  margin: 12px;
}

.Resources-tile a {
  text-decoration: underline;
}

.Resources-page-background img {
  width: 100%;
}

.Resources-padding {
  padding: 8px;
}

.Resources-tile-subtitle {
  margin-bottom: 0px;
  text-align: left;
  padding-left: 16px;
}
