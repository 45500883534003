.Wildcard {
}

.Wildcard-link {
  text-decoration: none;
  font-family: ubuntu;
  color: unset;
}

.Wildcard-content {
  padding-top: 16px;
  padding-bottom: 16px;
}

.Wildcard-title {
  font-size: 3rem;
  font-weight: 100;
}

.Wildcard-content .Wildcard-logo {
  width: 100%;
  max-width: 350px;
  height: auto;
}
