.HeadNav {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 48px;
  background-color: green;
  transition: 400ms;
  z-index: 10;
}

.HeadNav[data-hidden="true"] {
  top: -50px;
}

.HeadNav-content {
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.HeadNav-logo {
  margin-top: 8px;
  text-decoration: none;
}
