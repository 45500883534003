.JoinUs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.JoinUs-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  justify-content: flex-start;
  padding-top: 36px;
  padding-bottom: 36px;
}

.JoinUs-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}

.JoinUs-link {
  text-decoration: none;
  font-family: ubuntu;
  margin: 10px;
}

.JoinUs-item {
  width: 100%;
  transition: 150ms;
}

.JoinUs-item-content {
  padding: 8px;
}

.JoinUs-padding {
  padding: 8px;
}

@media (min-width: 768px) {
  .JoinUs-content-wrapper {
    justify-content: flex-start;
  }

  .JoinUs-content {
    flex-direction: row;
    justify-content: center;
  }

  .JoinUs-item {
    width: 300px;
  }
}

.JoinUs-link {
  transition: 400ms;
}
