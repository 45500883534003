.HeadNavMobile {
  display: block;
}

.HeadNavMobile-nav-button {
  /* This keeps the button over the drawer */
  position: relative;
}

.HeadNavMobile-drawer-content {
  min-width: 160px;
  width: 100%;
  height: 100%;
  padding-top: 60px;
}

.HeadNavMobile-drawer-links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.HeadNavMobile-drawer-link {
  text-decoration: none;
  font-size: 1.5rem;
  padding-top: 18px;
  padding-left: 10px;
}

@media (min-width: 768px) {
  .HeadNavMobile {
    display: none;
  }
}
