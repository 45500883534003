.BCTLogoLong {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.BCTLogoLong-image {
  object-fit: contain;
  height: auto;
  width: 100%;
  max-height: 48px;
}

.BCTLogoLong-text {
  font-weight: bold;
  font-family: ubuntu;
  margin: 0px;
  font-size: 36px;
  font-style: italic;
}
