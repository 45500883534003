.Header {
  height: 240px;
}

.Header-home-icon {
}

.Header-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  padding-top: 60px;
}

.Header-external-links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
