.Home {
}

.Home-page-title {
  padding: 1px;
  color: white;
}

.Home-page-title-arrow-wrapper {
  width: 100%;
  height: 18px;
  overflow: hidden;
  position: relative;
  padding-bottom: 5%;
}

.Home-page-title-arrow {
  width: 100%;
  height: 100%;
  background-size: 50.2% 100%;
  background-repeat: no-repeat;
  background-position: top left, top right;
}

.Home .Home-subheader {
  text-align: left;
}

.Home .Home-text {
  text-align: left;
  text-indent: 18px;
}

.Home .Home-list-icon {
  min-width: 44px;
}

.Home .Home-list-item {
  padding-left: 8px;
}

.Home-calls-to-action {
  margin: 18px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 400px;
}

.Home-cta-link {
  text-decoration: none;
}

.Home-cta-text {
  font-size: 1.2rem;
  line-height: 1.2rem;
  padding: 8px;
  margin: 0;
  font-weight: bold;
}
