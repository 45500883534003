.PageTitle {
  padding-top: 60px;
  padding-bottom: 8px;
}

.PageTitle-title {
  font-family: ubuntu;
}

.PageTitle-subtitle {
  font-family: ubuntu;
}
