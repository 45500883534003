.MeetupIcon {
}

.MeetupIcon > a {
    
}

.MeetupIcon-image {
  height: 32px;
  width: auto;
}
