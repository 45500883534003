.HeadNavDesktop {
  display: none;
}

.HeadNavDesktop-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
}

.HeadNavDesktop-link {
  text-decoration: none;
  font-size: 1.2rem;
  font-family: ubuntu;
}

.HeadNavDesktop-link > div {
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 8px;
  height: 100%;
  cursor: pointer;
}

.HeadNavDesktop-divider {
  height: 100%;
  width: 1px;
}

@media (min-width: 768px) {
  .HeadNavDesktop {
    display: block;
    height: 100%;
  }
}
