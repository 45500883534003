.BCTLogoShort {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.BCTLogoShort-text {
  font-weight: bold;
  font-family: ubuntu;
  margin: 0px;
}
